<div
	[hidden]="immediateCareObj.index === 1 && (showTelehealthTeaser$ | async) === false"
	class="immediate-care-card-container"
>
	<div class="header">
		<img class="service-icon" [src]="immediateCareObj.icon" alt="benefit-icon" />
		<div class="headline-wrapper">
			<span class="benefit-title">{{ immediateCareObj.title | translate }}</span>
			<span *ngIf="immediateCareObj.index === 1 || immediateCareObj.index === 4" class="availability">{{
				'Available 24 hours' | translate
			}}</span>
		</div>
	</div>
	<div class="header header-secondary">
		<img class="wallet-icon" src="/assets/icons/icon-wallet-coverage.svg" alt="wallet-icon" />
		<div>
			<div class="header disclaimers">
				<div>
					<span
						*ngIf="immediateCareObj.index !== 1 && immediateCareObj.benefit?.inNetwork"
						class="benefit-coverage"
						>{{ immediateCareObj.benefit?.inNetwork.includes('%') ? '' : ('You pay' | translate) }}
						{{ immediateCareObj.benefit?.inNetwork || 'Free service' | translate }}</span
					>
					<span *ngIf="immediateCareObj.index === 1" class="benefit-coverage">
						{{ 'Free service' | translate }}</span
					>
					<span *ngIf="immediateCareObj.index !== 1 && !immediateCareObj.benefit" class="benefit-coverage">
						{{ 'You pay average retail price $100 - $150' | translate }}</span
					>
				</div>
			</div>
			<span *ngIf="immediateCareObj?.benefit?.subjectToDeductableIn" class="subject-to-deductible">{{
				'Only after meeting your deductible' | translate
			}}</span>
		</div>
	</div>
	<div [ngSwitch]="isMobile">
		<div *ngSwitchCase="true">
			<div [ngClass]="{ expended: expended }" class="benefit-description-mobile">
				<div #benefitDescription>{{ immediateCareObj.description | translate }}</div>
			</div>
			<button *ngIf="showMoreTextPanel" class="bt-link" (click)="onShowMoreText()">
				{{ expended ? 'Show Less' : ('Show More' | translate) }}
			</button>
		</div>
		<p class="benefit-description-desk" *ngSwitchCase="false">{{ immediateCareObj.description | translate }}</p>
	</div>
	<ripple-button
		class="action-btn"
		[label]="'Find providers' | translate"
		[isFullWidth]="isMobile"
		(click)="onFindProvidersClicked()"
	>
	</ripple-button>
</div>
