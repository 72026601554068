import { Component, EventEmitter, HostBinding, Input, OnInit, Output, OnDestroy } from '@angular/core';
import {
	BehaviorSubject,
	map,
	Observable,
	of,
	shareReplay,
	switchMap,
	combineLatest,
	take,
	tap,
	Subscription,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { LabelColorTheme } from 'ripple';

import { isIonic } from '../../../utils/utils';
import { Maybe } from '../../../utils/types/maybe';
import { CommonComponent } from '../../../utils/components/common-component';
import { InputOf } from '../../../utils/input-reflector/input-of';
import { isNil } from '../../../utils/is/is-nil';
import { RxjsUtils } from '../../../utils/rxjs';

import { Benefit, CountLimitation, Network, NewBenefit } from '../../../models/benefits.model';
import { Contract } from '../../../models/contract.model';
import { NetworkStructure } from '../../../models/chat.model';
import { ServiceCoverage } from '../../../models/service-coverage.model';
import { FfBenefitsUiImprovementsPhase1 } from '../../../config/feature-flags/ff-benefits-ui-improvements-phase1';

import { AllServicesStoreService } from '../../../services/stores/all-services-store/all-services-store.service';
import { UserPlanDataStoreService } from '../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { BenefitStoreService } from '../../../services/stores/benefit-store/benefit-store.service';
import { NewBenefitStoreService } from '../../../services/stores/new-benefit-store/new-benefit-store.service';
import { AdditionalBenefitsStoreService } from '../../../services/stores/additional-benefits-store/additional-benefits-store.service';
import { RibbonEntitiesStoreService } from '../../../services/stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { MedicalConditionsStoreService } from '../../../services/stores/medical-conditions-store/medical-conditions-store.service';
import { CompanyStoreService } from '../../../services/stores/company-store/company-store.service';

import { TrackingService } from '../../../services/tracking.service';
import { IntercomConversationResponse, IntercomService } from '../../../services/intercom.service';
import { TelehealthDataService, telehealthSerivcesIDFromDB } from '../../../services/telehealth-data.service';
import { HomePageService } from '../../home-page/services/home-page.service';
import { UnleashService } from '../../../services/unleash.service';
import { HealtheeDialogService } from '../../../services/healthee-dialog.service';
import { ConversationService } from '../../../services/conversation.service';
import { BenefitsService } from '../../../services/benefits.service';

import { ExternalApiService } from '../../../services/external-api.service';
import { ServicesResponseUncategorized } from '../../../services/api/employee-api/helpers/services.response';
import { MedicalConditionsResponse } from '../../../services/api/employee-api/helpers/medical-conditions.response';

import { HealtheeDialogData } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { FeedbackDialogComponent } from '../../standalone/feedback/feedback-dialog/feedback-dialog.component';
import { FeedbackLocalize } from '../../standalone/feedback/feedback-localize';
import { FfNewCoverageInfoRestructure } from '../../../config/feature-flags/ff-new-coverage-info-layout';
import { T } from '@transifex/angular';

export enum BenefitType {
	OLD = 'old',
	NEW = 'new',
	NOT_CATEGORIZED = 'not_categorized',
	NEW_FROM_DDB = 'new_from_ddb',
}

@Component({
	selector: 'app-service-panel',
	templateUrl: './service-panel.component.html',
	styleUrls: ['./service-panel.component.scss'],
})
export class ServicePanelComponent extends CommonComponent implements OnInit, OnDestroy {
	@HostBinding('class.is-ionic')
	isIonic = isIonic();

	@Input()
	serviceId: Maybe<string>;

	@Input()
	isAdditionalService: Maybe<boolean> = false;

	@Input()
	isNotCategorized: Maybe<boolean> = false;

	@Input()
	hideFindProvidersButton: boolean = false;

	@Input() feature: string;

	@Input() data: object;

	@Output()
	closeClick = new EventEmitter<void>();

	@Output()
	relatedBenefitClick = new EventEmitter<string>();

	@Output()
	findProviderClick = new EventEmitter<void>();

	contentScrollPos$ = new BehaviorSubject<number>(0);

	isHeaderShrinked$: Observable<boolean>;

	service$: Observable<Maybe<ServicesResponseUncategorized>>;

	userBenefitsMap$: Observable<Map<string, any>>;

	benefit$: Observable<Maybe<(Benefit & { brochureFilePath?: string }) | any>>;

	serviceCaverage$: Observable<ServiceCoverage>;

	newBenefit$: Observable<Maybe<NewBenefit | any>>;

	newBenefitFromDDB$: Observable<Maybe<NewBenefit | any>>;

	activeNetworkTabIndex = 0;

	activeBenefit: BenefitType;

	showFindProvidersButton$: Observable<boolean>;

	public readonly NetworkStructure = NetworkStructure;

	protected readonly LabelColorTheme = LabelColorTheme;

	protected readonly OON_MN_NAME = 'out of network';
	protected readonly OON_SIMPLE_NAME = 'out network';
	protected readonly NOT_CATEGORIZED_EVENT_NAME = 'Benefit Gallery - Not Categorized';
	protected readonly NOT_CATEGORIZED_PREFIX_MESSAGE = 'Benefit Gallery - Chat Now';

	newSubscription: Subscription = new Subscription();

	isEnabledCoverageInfoRestructure: boolean = this.unleashService.isEnabled(FfNewCoverageInfoRestructure);
	isNewBenefitsFromDDBEnabled$ = this.unleashService.isEnabled$('ffNewBenefitsFromDDB');
	public isBenefitsUiImprovementsPhase1Enabled$ = this.unleashService.isEnabled$(FfBenefitsUiImprovementsPhase1);

	BenefitType = BenefitType;

	@T('Talk to agent')
	private txtTalkToAgent: string;

	@T('per')
	txtPer: string;

	@T('Required')
	txtRequired: string;

	@T('Not Required')
	txtNotRequired: string;

	constructor(
		private allServicesStoreService: AllServicesStoreService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private benefitStoreService: BenefitStoreService,
		private newBenefitStoreService: NewBenefitStoreService,
		private additionalBenefitsStoreService: AdditionalBenefitsStoreService,
		private ribbonServices: RibbonEntitiesStoreService,
		private trackingService: TrackingService,
		private homePageService: HomePageService,
		private unleashService: UnleashService,
		private dialogService: HealtheeDialogService,
		private medicalConditionsStoreService: MedicalConditionsStoreService,
		private externalApiService: ExternalApiService,
		private benefitsService: BenefitsService,
		private intercomService: IntercomService,
		private telehealthDataService: TelehealthDataService,
		private companyStoreService: CompanyStoreService,
		private conversationService: ConversationService
	) {
		super();
	}

	protected reflectInputs(): Array<InputOf<this>> {
		return [...super.reflectInputs(), 'serviceId', 'isAdditionalService'];
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.newSubscription.unsubscribe();
	}

	ngOnInit(): void {
		const serviceId$ = this.inputs.one('serviceId');
		const isAdditionalService$ = this.inputs.one('isAdditionalService');

		this.userBenefitsMap$ = this.homePageService.userBenefitsMap$;

		this.isHeaderShrinked$ = this.contentScrollPos$.pipe(
			map((pos) => pos > 160),
			shareReplay(1)
		);

		this.service$ = combineLatest([serviceId$, isAdditionalService$]).pipe(
			switchMap(([serviceId, isAdditionalService]) => {
				return isNil(serviceId)
					? of(null)
					: isAdditionalService
					? this.additionalBenefitsStoreService.byId(serviceId).pipe(
							map((benefit) =>
								isNil(benefit)
									? null
									: {
											abbreviate: benefit.name,
											name: benefit.name,
											title: benefit.name,
											type: '',
											_id: benefit._id,
											synonyms: [],
											iconURL: benefit.iconURL,
									  }
							)
					  )
					: this.allServicesStoreService.byId(serviceId);
			}),
			shareReplay(1)
		);

		this.benefit$ = combineLatest([serviceId$, isAdditionalService$]).pipe(
			switchMap(([serviceId, isAdditionalService]) => {
				return isNil(serviceId)
					? of(null)
					: isAdditionalService
					? this.additionalBenefitsStoreService.byId(serviceId).pipe(
							map((benefit) =>
								isNil(benefit)
									? null
									: {
											_id: benefit._id,
											inNetwork: '',
											outNetwork: '',
											additionalNetworks: [],
											blueOptionsNetwork: '',
											subjectToDeductibleOut: false,
											subjectToDeductibleIn: false,
											subjectToDeductibleBlueOptions: false,
											iconURL: '',
											title: benefit.name,
											desc: benefit.description,
											notes: [benefit.notes],
											relatedServices: [],
											brochureFilePath: benefit.brochureFilePath,
											brochureFileKey: benefit.brochureFileKey,
									  }
							)
					  )
					: this.benefitStoreService.get(serviceId);
			}),
			shareReplay(1)
		);
		this.benefit$ = combineLatest([
			this.benefit$,
			this.userPlanDataStoreService.getMnNetworkSortArray(),
			this.companyStoreService.get(),
			this.telehealthDataService.getTelehealthFreeServices(),
			this.allServicesStoreService.get(),
		]).pipe(
			map(([benefit, contractNetworks, company, freeTelehealthServices, allServices]) => {
				if (
					company?.premiumFeatures?.telehealth &&
					!!this.telehealthDataService.getRelatedTelehealthService(this.serviceId) &&
					!!benefit
				) {
					const relatedFreeTelehealthServiceId = this.telehealthDataService.getRelatedTelehealthService(
						this.serviceId
					);
					const relatedFreeTelehealthService = freeTelehealthServices.find(
						(s) => s._id === relatedFreeTelehealthServiceId
					);
					if (!benefit.relatedServices.find((r) => r._id === relatedFreeTelehealthServiceId)) {
						benefit.relatedServices.push(relatedFreeTelehealthService as any);
					}

					if (!benefit.relatedServices.find((r) => r._id === telehealthSerivcesIDFromDB)) {
						const teleehalthServiceFromDB = allServices.find((s) => s._id === telehealthSerivcesIDFromDB);
						benefit.relatedServices.push(teleehalthServiceFromDB as any);
					}
				}
				if (
					company?.premiumFeatures?.telehealth &&
					this.serviceId === telehealthSerivcesIDFromDB &&
					!!benefit
				) {
					freeTelehealthServices.forEach((s) => {
						if (!benefit.relatedServices.find((r) => r._id === s._id)) {
							benefit.relatedServices.push(s as any);
						}
					});
				}
				if (
					benefit?.networkStructure !== NetworkStructure.MN &&
					benefit?.networkStructure !== NetworkStructure.MTN
				)
					return benefit;
				return {
					...benefit,
					additionalNetworks: this.benefitsService.setMnBenefitsNetworkPriority(
						contractNetworks,
						benefit.additionalNetworks
					),
				};
			})
		);

		this.newBenefit$ = combineLatest([
			serviceId$.pipe(
				switchMap((serviceId) => (isNil(serviceId) ? of(null) : this.allServicesStoreService.byId(serviceId))),
				switchMap((serviceResp) =>
					isNil(serviceResp)
						? of(null)
						: this.userPlanDataStoreService.get().pipe(
								map((userPlanData) => {
									const type = serviceResp.type === 'medical' ? 'contract' : serviceResp.type;
									const plan: Contract = userPlanData[type];
									if (isNil(plan)) {
										return null;
									}
									return plan._id;
								})
						  )
				)
			),
			this.benefit$.pipe(
				RxjsUtils.isNotNil(),
				map((benefit) => (isNil(benefit) ? null : benefit._id))
			),
			this.benefit$.pipe(
				RxjsUtils.isNotNil(),
				map((benefit) => benefit?.createdBy2p0)
			),
		]).pipe(
			switchMap(([contractId, oldBenefitId, isNew]) => {
				this.trackingService.trackClientEvent('Benefit Panel', { type: this.getBenefitVersionType(isNew) });

				return isNil(contractId) || isNil(oldBenefitId) || !isNew
					? of({})
					: this.newBenefitStoreService.getByContractIdAndOldBenefitId({
							contractId,
							oldBenefitId,
					  });
			}),
			shareReplay(1)
		);

		this.newBenefitFromDDB$ = combineLatest([
			this.isNewBenefitsFromDDBEnabled$.pipe(RxjsUtils.isNotNil()),
			this.newBenefitStoreService.get().pipe(RxjsUtils.isNotNil()),
			serviceId$.pipe(RxjsUtils.isNotNil()),
		])
			.pipe(RxjsUtils.isNotNil())
			.pipe(
				switchMap(([isNewBenefitsFromDDBEnabled, userBenefits, serviceId]) => {
					if (!isNewBenefitsFromDDBEnabled) return of({});

					const benefit = userBenefits?.benefits?.find((benefit) => benefit.service === serviceId);
					return of(benefit);
				})
			);

		this.newSubscription = combineLatest([this.benefit$, this.newBenefit$, this.newBenefitFromDDB$])
			.pipe(
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				filter(([benefit, newBenefit, newBenefitFromDDB]) => newBenefit !== null && newBenefitFromDDB !== null), // Filter out null or undefined
				take(1),
				tap(([benefit, newBenefit, newBenefitFromDDB]) => {
					if (newBenefitFromDDB?.oid) {
						this.activeBenefit = BenefitType.NEW_FROM_DDB;
					} else if (newBenefit?.oid) {
						this.activeBenefit = BenefitType.NEW;
					} else if (benefit?._id) {
						this.activeBenefit = BenefitType.OLD;
					} else {
						this.activeBenefit = BenefitType.NOT_CATEGORIZED;
					}
				})
			)
			.subscribe();

		this.showFindProvidersButton$ = combineLatest([
			isAdditionalService$,
			this.externalApiService.isUserSupportedByTalon(),
			serviceId$,
			this.ribbonServices.getHealthServices(),
		]).pipe(
			map(([isAdditionalService, hasTalon, serviceId, ribbonServices]) => {
				if (isAdditionalService) return false;
				if (hasTalon) return true;
				return !!ribbonServices.find((service) => service._id === serviceId);
			})
		);

		this.subsBag.add = serviceId$.subscribe({
			next: () => this.contentScrollPos$.next(0),
		});

		this.serviceCaverage$ = combineLatest([this.service$, this.userBenefitsMap$]).pipe(
			map(([service, userBenefitsMap]) => {
				if (userBenefitsMap.get(service._id)?.covered === ServiceCoverage.covered) {
					return ServiceCoverage.covered;
				} else if (this.isNotCategorized) {
					return ServiceCoverage.not_categorized;
				} else {
					return ServiceCoverage.not_covered;
				}
			}),
			shareReplay(1)
		);
	}

	public setActiveTab(index: number) {
		this.activeNetworkTabIndex = index;
	}

	public getAppointmentChecklistCount(network: Network) {
		if (!network) return 0;

		let counter = 0;
		if (network.referralRequired) counter++;
		if (network.preAuth) counter++;
		return counter;
	}

	public getImportantToKnowCount(network: Network, gender: string) {
		let counter = 0;
		if (gender) counter++;
		if (network.ageRestriction) counter++;
		if (network.countLimitations) counter++;
		if (network.preExistConditions?.length) counter++;
		if (network.notes?.length) counter++;
		return counter;
	}

	public onFeedbackClick(rating) {
		this.trackingService.trackClientEvent('Feedback Benefit panel', { rating, feature: this.feature });
		const options: HealtheeDialogData = {
			hasCloseButton: true,
			component: FeedbackDialogComponent,
			data: {
				rating,
				feature: this.feature,
				data: this.data,
				headline: FeedbackLocalize.headline,
				question: FeedbackLocalize.question,
			},
		};
		return this.dialogService.open(options);
	}

	public getMedicalConditionById(id: string): Observable<Maybe<MedicalConditionsResponse>> {
		return this.medicalConditionsStoreService.byId(id);
	}

	public getCountLimitationsText(countLimitations: CountLimitation) {
		let returnString = '';
		if (countLimitations.serviceCount) {
			returnString += countLimitations.serviceCount + ` ${this.txtPer} `;
		}
		if (countLimitations.timeUnitCount) {
			returnString += countLimitations.timeUnitCount + ' ';
		}
		if (countLimitations.timeUnit) {
			returnString +=
				countLimitations.timeUnitCount === 1 && countLimitations.timeUnit.endsWith('s')
					? countLimitations.timeUnit.slice(0, -1)
					: countLimitations.timeUnit;
		}
		return returnString;
	}

	trackBrochureEvent() {
		this.benefit$.pipe(RxjsUtils.isNotNil(), take(1)).subscribe({
			next: (benefit) =>
				this.trackingService.trackClientEvent('Coverage Info Employer Benefit link click', {
					'service name': benefit.title,
				}),
		});
	}

	onFindProvidersClick() {
		this.findProviderClick.emit();
		this.benefit$.pipe(RxjsUtils.isNotNil(), take(1)).subscribe({
			next: (benefit) =>
				this.trackingService.trackClientEvent('Benefit Card Find Providers Click', {
					'Service Name': benefit.title,
					'Benefit type': this.getBenefitVersionType(benefit.createdBy2p0),
				}),
		});
	}

	getBenefitVersionType(createdBy2p0: boolean | undefined) {
		return createdBy2p0 ? 'New' : 'Old';
	}

	openIntercom() {
		const simulatedUserMessageForInitiatingIntercomFlow = this.txtTalkToAgent; // Shown as the first message in an intercom conversation after escalation
		const zoeWelcomeResponse = this.intercomService.zoeWelcomeMessageOnIntercom;

		this.service$.pipe(RxjsUtils.isNotNil(), take(1)).subscribe((service) => {
			this.intercomService
				.initiateIntercomConversation(
					simulatedUserMessageForInitiatingIntercomFlow,
					zoeWelcomeResponse,
					`Triggered by <strong>${this.NOT_CATEGORIZED_PREFIX_MESSAGE}</strong>,
<strong>Service Name:</strong> ${service?.name},
<strong>Service Id:</strong> ${service?._id},
<strong>Coverage:</strong> Not Categorized`
				)
				.subscribe((intercomResponse: IntercomConversationResponse) => {
					this.intercomService.openConversation(intercomResponse.conversationId);
					this.conversationService.clearIntercomConversation();
				});

			this.intercomService
				.sendIntercomEvent(this.NOT_CATEGORIZED_EVENT_NAME, {
					serviceId: service?._id,
					serviceName: service?.name,
				})
				.subscribe();
		});
	}
}
